import {
    isSafari,
    isMacOs, isIE, isIOS, isEdge, isFirefox, isMobile, isTablet
} from "react-device-detect";

export default function webp() {
    if((isSafari === true && isMacOs === true) || isIE || isIOS || isEdge || isFirefox){
        return false;
    }else{
        return checkWebp;
    }
}

function checkWebp() {
    let elem = document.createElement('canvas');

    if (!!(elem.getContext && elem.getContext('2d'))) {
        // was able or not to get WebP representation
        return (elem.toDataURL('image/webp').indexOf('data:image/webp') === 0);
    }else{
        return false;
    }
}
